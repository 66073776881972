import { List, Space } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "src/context/GlobalContext";
import AmityLogo from "../../assets/images/amity.png";
import LightLogo from "../../assets/images/logo.png";
const HomePage = () => {
  const { levels } = useContext(GlobalContext);
  const navigate = useNavigate();
  return (
    <div className="container mx-auto p-2 min-h-screen">
      <img src={LightLogo} className="h-16" alt="logo" />
      <div className="my-4 lg:mt-0 w-full text-center">
        <img src={AmityLogo} alt="amity logo" className="h-20 mx-auto" />
        <div className="text-sky-500 font-bold text-3xl pt-2 text-center">
          Primary Resources 2024
        </div>
      </div>

      {/* <p className="font-semibold text-lg text-center text-red-500">
        The site is currently undergoing maintenance. <br />
        We apologize for any inconvenience.
        <br /> It will be back online as soon as possible. <br />
        Thank you for your understanding.
      </p> */}
      <p className="font-semibold text-lg text-red-500">
        PLEASE CHOOSE YEAR LEVEL
      </p>
      {/* <p className="font-semibold text-2x text-red-500">
        The system is being updated for book sales in 2024.
      </p> */}

      {levels && (
        <List
          loading={levels === null}
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 4,
            lg: 8,
            xl: 8,
            xxl: 8,
          }}
          dataSource={levels}
          renderItem={(item, index) => (
            <List.Item onClick={() => navigate("/store/" + item.id)}>
              <div>
                <div className="bg-white p-4 text-center border border-gray-400 hover:border-gray-400 hover:shadow-lg  mt-4 text-base font-semibold cursor-pointer aspect-square items-center flex justify-center  shadow-inner">
                  <Space direction="vertical" size="small">
                    <span className="text-sky-400 text-7xl font-extrabold">
                      {index === 0 ? "KG" : index}
                    </span>

                    {item.name}
                  </Space>
                </div>
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default HomePage;
